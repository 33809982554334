import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../containers/contact"

type ContactPageProps = {}

const ContactPage: React.FunctionComponent<ContactPageProps> = (props: any) => {
  const { data } = props;
    const settings = data.ghostSettings;
  return (
    <Layout>
      <SEO
        title="Contact Us"
        description={settings.description}
      />

      <Contact />
    </Layout>
  )
}



export default ContactPage

export const postQuery = graphql`
{
  ghostSettings {
    title
    description
  }
}
`